<template>
  <div class="master-toko">
    <header-page>
      <span
        class="size14 fw-bold-700"
        style="color: #0B5FFF;margin-right: 5px;"
      >Master</span>
      <span
        class="text-dark text-darken-5"
        style="margin-right: 5px;"
      >></span>
      <span class="text-dark fw-bold-400 size14">Brand</span>
    </header-page>

    <TableBrand
      :result="result"
      :delete-data="deleteData"
      :get-data="getData"
      :edit-item="editItem"
      @filter="getFilter"
    />

    <ModalBrand
      :form-data="formPayload"
      :create-item="createItem"
      :edit-id="editId"
      :edit-item="editItem"
      :update-item="updateItem"
      :clean-up-form="cleanUpForm"
      :icon-bank="iconBank"
      @getPayload="getPayload"
    />

  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash'
import {
  VBToggle,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

import HeaderPage from '@/components/HeaderPage.vue'
import ModalBrand from '@/components/Master/Brand/Modal/ModalBrand.vue'
import TableBrand from '@/components/Master/Brand/TableBrand.vue'

export default {
  title() {
    return 'Master Brand'
  },
  components: {
    HeaderPage,
    ModalBrand,
    TableBrand,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      currentPage: 1,
      isLoading: true,
      result: {},
      validations: [],
      editId: null,
      iconBank: null,
      formPayload: {
        name: '',
        description: '',
        logo: '',
      },
      filter: {
        search: '',
        per_page: 10,
        sort_type: 'asc',
      },
    }
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData()
      }, 300),
      deep: true,
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getPayload(value) {
      this.formPayload = value
    },
    getFilter(value) {
      this.filter = value
    },
    cleanUpForm() {
      this.editId = null
      this.iconBank = null
      this.formPayload = {
        name: '',
        description: '',
        logo: '',
      }
      this.validations = ''
    },
    editItem(item) {
      this.cleanUpForm()
      this.editId = item.uuid
      this.iconBank = item.logo_url
      this.$store.commit('brand/setFormPayload', this.formPayload = {
        name: item.name,
        description: item.description,
      })
      this.$bvModal.show('modal-brand')
    },
    async getData(page = 1) {
      this.isLoading = true
      const queryParams = this.filter
      queryParams.page = page
      this.$store.dispatch('brand/getData', { params: queryParams }).then(result => {
        this.isLoading = false
        this.result = result.data
        this.currentPage = result.data.current_page
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
    createItem() {
      const form = this.preparePayload()
      this.isLoading = true
      this.$store.dispatch('brand/postData', {
        query: '',
        payload: form,
      })
        .then(() => {
          this.getData()
          successNotification(this, 'Success', 'Brand berhasil di buat')
          this.cleanUpForm()
          this.$bvModal.hide('modal-brand')
          this.isLoading = false
        })
        .catch(error => {
          if (error.response.data.meta.validations) {
            this.validations = error.response.data.meta.validations
          }
          this.isLoading = false
        })
    },
    updateItem() {
      const form = this.preparePayload()
      this.isLoading = true
      form.append('_method', 'PATCH')

      this.$store.dispatch('brand/postData', {
        query: `/${this.editId}`,
        payload: form,
      })
        .then(() => {
          this.$bvModal.hide('form-bank-modal')
          this.getData()
          successNotification(this, 'Success', 'Brand berhasil di ubah!')
          this.cleanUpForm()
          this.isLoading = false
          this.$bvModal.hide('modal-brand')
        })
        .catch(error => {
          if (error.response.data.meta.validations) {
            this.validations = error.response.data.meta.validations
            errorNotification(this, 'Oops!', error.response.data.meta.messages)
          }
          this.isLoading = false
        })
    },
    async deleteData(uuid) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure to delete this brand?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('brand/deleteData', `/${uuid}`)
            .then(() => {
              this.getData()
              this.$swal({
                icon: 'success',
                title: 'Success!',
                text: 'Brand berhasil dihapus',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(error => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(this, 'Oops!', error.response.data.meta.messages)
              }
            })
        }
      })
    },
    preparePayload() {
      const form = new FormData()
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayload) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] != null) {
          form.append(key, this.formPayload[key])
        }
      }
      return form
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';

.px-33 {
  padding-left: 33px;
  padding-right: 33px;
}
.px-16 {
  padding-left: 16px;
  padding-right: 16px;
}
.py-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}
.py-18 {
  padding-top: 18px;
  padding-bottom: 18px;
}


.master-toko {
  height: 100vh
}
</style>
